<template>
  <div>
    <div class="ps-page--product">
      <div class="ps-container">
        <div class="ps-page__container">
          <div class="ps-page__left">
            <div class="ps-product--detail ps-product--fullwidth">
              <div class="ps-product__header">
                <div class="ps-product__thumbnail" data-vertical="true">
                  <figure>
                    <div class="ps-wrapper">
                      <div class="ps-product__gallery" data-arrow="true">
                        <div
                          v-if="listing.images.length > 0"
                          class="item"
                          v-for="image in listing.images"
                          :key="image.id"
                        >
                          <img :src="`${image.url}`" alt="Product" />
                        </div>
                      </div>
                    </div>
                  </figure>
                  <div
                    class="ps-product__variants"
                    data-item="4"
                    data-md="4"
                    data-sm="4"
                    data-arrow="false"
                  >
                    <div
                      class="item"
                      v-for="image in listing.images"
                      :key="image.id"
                    >
                      <img :src="`${image.url}`" alt="Product" />
                    </div>
                  </div>
                </div>
                <div class="ps-product__info">
                  <h1>{{ listing.title }}</h1>
                  <div class="ps-product__meta">
                    <p class="p-3">
                      Category: &nbsp;
                      <a href="#">{{ listing.category.name }}</a>
                    </p>
                  </div>
                  <h4 class="ps-product__price">${{ listing.price }}</h4>
                  <div class="ps-product__desc">
                    <p v-if="listing.user != null">
                      Sold By:{{ listing.user.name }} 2
                    </p>
                    <ul class="ps-list--dot">
                      <li>{{ listing.description }}</li>
                    </ul>
                  </div>

                  <div>
                    <form>
                      <div class="form-group">
                        <label for="catDescription">Send a Message</label>
                        <textarea
                          class="form-control"
                          id="catDescription"
                          rows="3"
                          v-model="form.description"
                        ></textarea>
                        <small
                          v-if="errors && errors.description"
                          class="text-danger"
                        >
                          {{ errors.description[0] }}
                        </small>
                      </div>

                      <div class="justify-content-between mb-4 mt-4">
                        <div>
                          <button
                            v-if="auth.isAuthenticated"
                            :disabled="isLoading"
                            type="submit"
                            class="ps-btn btn-sm ls-button"
                            @click.prevent="onSubmit()"
                          >
                            <i
                              v-if="!isLoading"
                              class="icon-floppy-disk ml-1"
                            ></i>
                            <i v-if="isLoading" class="icon-refresh ml-1"></i>
                            {{ isLoading ? "Processing..." : "Reply" }}
                            <div
                              v-if="isLoading"
                              class="spinner-border text-light"
                              role="status"
                            ></div>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import global from "@/mixins/global.js";
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "Listing",
  data() {
    return {
      listingId: "",
      listing: {},
      loading: true,
      messages: [],
      quantity: 1,
      form: {
        description: "",
      },
    };
  },
  mixins: [global],
  mounted() {
    this.listingId = this.$route.params.id;
    this.getListing();
  },
  computed: {
    ...mapState({
      auth: (state) => state.auth,
      cart: (state) => state.cart,
    }),
  },
  methods: {
    getListing() {
      this.loading = true;
      axios
        .get(`listing/` + this.listingId)
        .then((response) => {
          this.listing = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    createReply() {
      this.isLoading = true;
      axios
        .post("listings", this.formData)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.handleSuccess("Reply has been sent");
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.handleErrors(error, "Failed to send Reply");
        });
    },
  },
};
</script>

<style lang="css" scoped>
.main-banner {
  height: 200px;
}
.search-input {
  width: 100%;
}
.btn-warning {
  width: 200px;
}
.vn-message {
  color: white !important;
}
</style>
